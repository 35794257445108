@import './_variables.scss';

body,
html,
#root {
  position: relative;
  min-height: 100vh;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  min-width: 300px;
}

.brand-name,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

// Elemental styles
.fa-circle {
  color: $solid-color;
}
.fa-tint {
  color: $liquid-color;
}
.fa-cloud {
  color: $gas-color;
}

.legend-item {
  &:not(.active):not(:hover) {
    background-color: transparent !important;
  }
  &.active p,
  &:hover p {
    // background-color: transparent !important;
    color: #333 !important;
    cursor: pointer;
  }
}
