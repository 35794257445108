$alkali-metals: #f8b4cb;
$alkali-earth-metals: #ecbd80;
$transition-metals: #feffac;
$basic-metal: #a2f7a2;
$semimetal: #afdff5;
$nonmetals: #87b3ec;
$halogen: #b7a5f8;
$noble-gasses: #ce94f5;
$metalloids: #7bf0ff;
$lanthanoids: #fbe9e7;
$actinoids: #d7ccc8;

$dark: #333;
$darker: #222;

$light: #eee;
$lighter: #fff;

$solid-color: #777;
$liquid-color: #1300be;
$gas-color: #278800;
